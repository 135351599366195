@import url('https://fonts.googleapis.com/css2?family=Arima:wght@100..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

body {
  background-image: url('/public/dashback.png');
  height: 100vh;
  width: 100vw;
  margin-right: 0;
  object-fit: contain;
}

